<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <div>
          <b-card>
            <b-tabs
              content-class="mt-2"
              justified
            >
              <b-tab
                title="Requests"
                active
              >
                <requests />
              </b-tab>
              <b-tab title="E-Meetings">
                <emeetings />
              </b-tab>
            </b-tabs>
          </b-card>

        </div>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCol, BRow, BTab, BTabs, BOverlay,
} from 'bootstrap-vue'
import Requests from '@/views/incubator-portal/startups/pages/startup-manager/CommRequests.vue'
import Emeetings from '@/views/incubator-portal/programs/pages/startups/program-manager/CommEmeetManager.vue'

export default {
  components: {
    Requests,
    Emeetings,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BOverlay,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
